import { IMPRESSION_GROUP_ID, IMPRESSION_GROUP_TYPE } from './constants'

export const getRecommendedOffers = (data) =>
  data?.accountsViewer?.userRecommendations.offers.edges ?? null

export const getUserLoggedInStatus = (data) => data?.accountsViewer?.isLoggedIn

export const getImpressionContent = (groupPosition) => ({
  groupType: IMPRESSION_GROUP_TYPE,
  groupId: IMPRESSION_GROUP_ID,
  groupPosition
})
