import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'

const NUMBER_OF_OFFERS = 20
export const RECOMMENDED_SUFFIX = '?source=personal_strip'
export const IMPRESSION_GROUP_TYPE = 'personalisation'
export const IMPRESSION_GROUP_ID = 'recommended_for_you'
export const RECOMMENDED_OFFERS_TITLE_FALLBACK = 'Handpicked student deals'
export const RECOMMENDED_OFFERS_SUBTITLE_FALLBACK = `We think you'll love these student discounts`

export const RECOMMENDED_OFFERS_RAIL_QUERY = gql`
  query RecommendedOffersRailQuery($countryCode: String!) {
    accountsViewer {
      id
      isLoggedIn
      userRecommendations (countryCode: $countryCode) {
        recommendationType
        offers (first: ${NUMBER_OF_OFFERS}) {
          edges {
            node {
              ...OfferTileNewOffer
            }
          }
        }
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
`
