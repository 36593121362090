'use client'

import PropTypes from 'prop-types'

import OfferRail from '@components/offer_rail'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { useQuery } from '@hooks/use_query'

import {
  RECOMMENDED_OFFERS_SUBTITLE_FALLBACK,
  RECOMMENDED_OFFERS_TITLE_FALLBACK,
  RECOMMENDED_SUFFIX,
  RECOMMENDED_OFFERS_RAIL_QUERY
} from './constants'
import {
  getRecommendedOffers,
  getImpressionContent,
  getUserLoggedInStatus
} from './helpers'

const RecommendedOffersRail = ({ groupPosition }) => {
  const { t } = useI18n()
  const country = useCountryCode()

  const { data, loading } = useQuery(RECOMMENDED_OFFERS_RAIL_QUERY, {
    variables: {
      countryCode: country
    }
  })

  const offers = getRecommendedOffers(data)
  const isUserLoggedIn = getUserLoggedInStatus(data)

  if (!loading && !isUserLoggedIn) return null

  return (
    <div data-testid='recommended-for-you-rail'>
      <OfferRail
        hasMarginBottom
        impressionContent={getImpressionContent(groupPosition)}
        loading={loading}
        numberOfPlaceholders={4}
        offers={offers}
        pathSuffix={RECOMMENDED_SUFFIX}
        subtitle={t('m_recommended_for_you_subtitle', {
          fallback: RECOMMENDED_OFFERS_SUBTITLE_FALLBACK
        })}
        title={t('d_recommended_for_you_title', {
          fallback: RECOMMENDED_OFFERS_TITLE_FALLBACK
        })}
        viewMoreHref={getNextRoute('recommended.show', { country })}
      />
    </div>
  )
}

RecommendedOffersRail.propTypes = {
  groupPosition: PropTypes.number.isRequired
}

export default RecommendedOffersRail
